:root {
  --color-light: #fff;
  --color-dark: #000;
  --color-primary-muted: #d5e4f0;
  --color-muted: #b3b3b3;
  --font-family-heading: "Condor", sans-serif;
}

@font-face {
  font-family: "Condor";
  src: url("../src/assets/fonts/Condor-Black.woff2") format("woff2"),
    url("../src/assets/fonts/Condor-Black.woff") format("woff");
  font-weight: 900;
}

@font-face {
  font-family: "Condor";
  src: url("../src/assets/fonts/Condor-Regular.woff2") format("woff2"),
    url("../src/assets/fonts/Condor-Regular.woff") format("woff");
  font-weight: 400;
}

@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
  box-sizing: border-box;
}

body {
  font-size: 16px;
  color: var(--color-dark);
  font-family: "Lato", sans-serif;
}

.heading {
  font-family: var(--font-family-heading);
  font-weight: 400;
}

.heading--watermark {
  font-size: 8vw;
  font-weight: 900;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--color-primary-muted);
  -webkit-text-fill-color: var(--color-light);
  margin-top: 100px;
}

@media print {
    .heading--watermark {
        -webkit-text-stroke-width: 0px;
        -webkit-text-stroke-color: unset;
        -webkit-text-fill-color: unset;
        font-weight: 400;
        margin-top: 0;
    }
}

h2 {
  font-size: 2rem;
  position: relative;
  width: fit-content;
  margin: 1rem 0 0.5rem 0;
}

h2::after {
  content: "";
  position: absolute;
  background-color: var(--color-primary-muted);
  width: 100%;
  height: 1px;
  bottom: 6px;
  left: 0;
  z-index: -1;
}

a {
  text-decoration: none;
  color: var(--color-dark);
}

a:hover {
  text-decoration: underline;
}
